module.exports = {
  defaultTitle: 'David Langstein',
  logo: 'https://davidlangstein.com/favicon/favicon-512.png',
  author: 'David Langstein',
  url: 'https://davidlangstein.com',
  legalName: 'David Langstein',
  defaultDescription: 'I’m David and I’m a Backend & Frontend engineer!',
  socialLinks: {
    // twitter: 'http://www.twitter.com/',
    github: 'https://github.com/dlangstein',
    linkedin: 'https://www.linkedin.com/in/dlangstein/',
    // instagram: 'https://instagram.com/',
    // youtube: 'https://www.youtube.com/user/',
    // google: 'https://plus.google.com/u/0/',
  },
  googleAnalyticsID: 'UA-175294094-1',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'appId',
    twitter: '',
  },
  address: {
    city: 'New York',
    region: 'Region',
    country: 'United States',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'dclangstein@gmail.com',
    phone: 'phone number',
  },
  foundingDate: '2020',
};
